import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Checkbox, Stack, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useToast from "hooks/useToast";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { updateCourse } from "store/courses";
import { getCourseDetail } from "store/courses";
import { getUserTypeDetail } from "store/userTypes";

// Recursive component to render nested structure
const NestedList = ({ items, handleSelectionChange, selectedItems, searchQuery }) => {
  return (
    <>
      {items.length > 0 &&
        items.map((item) => (
          <NestedItem
            key={item._id}
            item={item}
            items={items} // Pass all items for recursion
            handleSelectionChange={handleSelectionChange}
            selectedItems={selectedItems}
            searchQuery={searchQuery}
          />
        ))}
    </>
  );
};

// Define prop types for NestedList
NestedList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      child: PropTypes.array,
    })
  ).isRequired,
  handleSelectionChange: PropTypes.func.isRequired, // handle selection change function
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired, // array of selected item IDs
  searchQuery: PropTypes.string,
};

// Component to render each item with lines indicating hierarchy
const NestedItem = ({
  item,
  level = 0,
  items, // Pass all items for recursion
  handleSelectionChange,
  selectedItems,
  searchQuery,
}) => {
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };

  const isChecked = selectedItems.includes(item._id);

  // Highlight matching text
  const highlightText = (text, query) => {
    if (!query) return text;

    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query ? (
        <Typography
          key={index}
          component="span"
          sx={{ backgroundColor: "yellow", fontSize: "14px" }}
        >
          {part}
        </Typography>
      ) : (
        part
      )
    );
  };

  // Check if the item or its children match the query
  const doesMatchQuery = (node, query) => {
    if (node.name.toLowerCase().includes(query)) return true;
    if (node.child && node.child.some((child) => doesMatchQuery(child, query))) return true;
    return false;
  };

  const matchesQuery = doesMatchQuery(item, searchQuery);

  if (!matchesQuery) return null; // Hide items that don't match the search query

  return (
    <Box sx={{ position: "relative", paddingLeft: "20px", paddingBottom: "10px" }}>
      {level > 0 && (
        <Box
          sx={{
            position: "absolute",
            left: "-10px",
            top: "0",
            bottom: "0",
            width: "2px",
            backgroundColor: "#000",
            marginLeft: "9px",
          }}
        />
      )}

      <Stack direction="row" alignItems="center" gap="10px">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            paddingLeft: "5px",
          }}
        >
          {open ? (
            <ExpandLessIcon onClick={handleToggle} />
          ) : (
            <ExpandMoreIcon onClick={handleToggle} />
          )}
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleSelectionChange(item._id, e.target.checked, items)}
          />
          <Typography sx={{ fontSize: "14px" }} onClick={handleToggle}>
            {highlightText(item.name, searchQuery)}
          </Typography>
        </Box>
      </Stack>

      {open && item.child && item.child.length > 0 && (
        <Box sx={{ paddingLeft: "10px" }}>
          <NestedList
            items={item.child}
            handleSelectionChange={handleSelectionChange}
            selectedItems={selectedItems}
            searchQuery={searchQuery}
          />
        </Box>
      )}
    </Box>
  );
};

// Define prop types for NestedItem
NestedItem.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    child: PropTypes.array,
  }).isRequired,
  level: PropTypes.number, // This is optional, and defaults to 0 if not passed
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      child: PropTypes.array,
    })
  ).isRequired,
  handleSelectionChange: PropTypes.func.isRequired, // function for handling the selection change
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired, // array of selected item IDs
  searchQuery: PropTypes.string,
};

function EditCourse() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { successToast, errorToast } = useToast();
  const { courseDetail, error, success } = useSelector((state) => state.course);
  const { userTypeDetail, loading } = useSelector((state) => state.userType);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userTypes: [],
      courseName: "",
      subCourseNames: [{ name: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "subCourseNames",
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  useEffect(() => {
    // Dispatch actions to fetch user types and course details
    dispatch(getUserTypeDetail("all"));
    dispatch(getCourseDetail(id));
  }, [id]);

  useEffect(() => {
    if (error) {
      errorToast(error);
      return;
    }
    if (success) {
      console.log(success, "Success");
      successToast(success);

      return;
    }
  }, [error, success]);

  // Use this effect to populate the form with course details
  useEffect(() => {
    if (courseDetail) {
      // Set values based on fetched courseDetail
      setSelectedItems((prev) => courseDetail.courseDetail?.userTypes ?? []);
      setValue("userTypeIds", courseDetail.courseDetail?.userTypes ?? []);
      setValue("courseName", courseDetail.courseDetail.courseName);
      setValue(
        "subCourseNames",
        courseDetail.subCourses.map((subCourse) => ({
          name: subCourse.courseName,
          id: subCourse._id,
        }))
      );
    }
  }, [courseDetail, setValue]);

  const onSubmit = (data) => {
    if (selectedItems.length == 0) {
      return errorToast("First select all the usertypes");
    }

    const formData = {
      userTypeIds: selectedItems,
      courseName: data.courseName,
      subCourseNames: data.subCourseNames,
    };

    console.log(formData, "formData");

    // Handle form submission logic here
    dispatch(updateCourse(id, formData));
  };

  const handleSelectionChange = (itemId, isChecked, items) => {
    const updateSelection = (id, checked, allItems) => {
      // Helper function to recursively add/remove child nodes
      const getDescendants = (item) => {
        const descendants = [];
        const traverse = (node) => {
          descendants.push(node._id);
          if (node.child && node.child.length) {
            node.child.forEach(traverse);
          }
        };
        traverse(item);
        return descendants;
      };

      // Find the current item
      const currentItem = allItems.find((item) => item._id === id);

      if (currentItem) {
        const descendants = getDescendants(currentItem);
        if (checked) {
          setSelectedItems((prev) => [...new Set([...prev, id, ...descendants])]);
        } else {
          setSelectedItems((prev) => prev.filter((item) => ![id, ...descendants].includes(item)));
        }
      }
    };

    updateSelection(itemId, isChecked, items);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Stack p={3} gap={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h5">Edit Course Detail</Typography>
                <Stack gap={3} direction={"row"}>
                  <Box flex={1}>
                    <Grid container spacing={2}>
                      {/* Course Name Input */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Enter Course Name"
                          variant="outlined"
                          fullWidth
                          {...register("courseName", { required: "Course name is required" })}
                          error={!!errors.courseName}
                          helperText={errors.courseName?.message}
                          InputLabelProps={{
                            shrink: true, // This prop ensures the label shrinks properly
                          }}
                          margin="normal" // Adds some margin for better spacing
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => append({ name: "" })}
                          sx={{ marginTop: "18px" }}
                        >
                          <Typography
                            sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}
                          >
                            Add Sub-Course
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>

                    {fields.map((field, index) => (
                      <Grid container spacing={2} key={field.id}>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={9} sm={4}>
                          <TextField
                            label={`Sub-Course ${index + 1}`}
                            variant="outlined"
                            fullWidth
                            {...register(`subCourseNames.${index}.name`, {
                              required: "Sub-course name is required",
                            })}
                            error={!!errors.subCourseNames?.[index]?.name}
                            helperText={errors.subCourseNames?.[index]?.name?.message}
                          />
                        </Grid>
                        <Grid item xs={2} sm={1}>
                          <Box
                            sx={{
                              borderColor: "#ccc",
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderRadius: 1.5,
                              display: "inline-flex",
                              padding: 0.65,
                              cursor: "pointer",
                            }}
                            onClick={() => remove(index)}
                          >
                            <DeleteIcon sx={{ fontSize: "28px", color: "#aaa" }} />
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                  <Box flex={1} sx={{ height: "60vh", overflow: "auto" }}>
                    <Typography>Select User Types</Typography>
                    <TextField
                      placeholder="Search user types"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={searchQuery}
                      onChange={handleSearchChange}
                      sx={{ mb: 2 }}
                    />
                    {loading ? (
                      <Typography variant="h6" mt={5}>
                        Loading ...{" "}
                      </Typography>
                    ) : (
                      <NestedList
                        items={userTypeDetail ?? []}
                        handleSelectionChange={handleSelectionChange}
                        selectedItems={selectedItems}
                        searchQuery={searchQuery}
                      />
                    )}
                  </Box>
                </Stack>
                <Box>
                  <Button variant="outlined" size="small" sx={{ marginRight: 2 }}>
                    <Link to="/courses">
                      <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                        Back
                      </Typography>
                    </Link>
                  </Button>
                  <Button type="submit" variant="contained" size="small">
                    <Typography sx={{ color: "#fff", fontSize: "16px", textTransform: "none" }}>
                      Update & Continue
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCourse;
