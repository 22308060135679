const appBar = {
  defaultProps: {
    color: "rgba(0, 0, 0, 0)",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};

export default appBar;
