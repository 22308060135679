// routes.js

import Icon from "@mui/material/Icon";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import ProfilesList from "examples/Lists/ProfilesList";
import Activities from "layouts/activities/list";
import SignIn from "layouts/authentication/sign-in";
import Transaction from "layouts/billing/components/Transaction";
import Chapters from "layouts/chapters/list";
import AddCourse from "layouts/courses/add";
import EditCourse from "layouts/courses/edit";
import Courses from "layouts/courses/list";
import Dashboard from "layouts/dashboard";
import Overview from "layouts/profile";
import Subjects from "layouts/subjects/list";
import AddTopic from "layouts/topics/add";
import Topics from "layouts/topics/list";
import AddAcademic from "layouts/academics/add";
import EditAcademic from "layouts/academics/edit";
import AcademicList from "layouts/academics/list";
import User from "layouts/users/list";
import ViewUser from "layouts/users/view";
import AddUsertype from "layouts/usertypes/add";
import UserTypesList from "layouts/usertypes/list";
import ViewUserType from "layouts/usertypes/view";
import AddSubject from "layouts/subjects/add";

const routes = [
  {
    type: "none",
    id: 1,
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },

  // For dashboard
  {
    type: "title",
    id: 2,
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <ProtectedRoute component={<Dashboard />} />, // Protect this route
  },

  // For users
  {
    type: "title",
    id: 4,
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users",
    component: <ProtectedRoute component={<User />} />, // Protect this route
  },
  {
    type: "none",
    id: 4,
    name: "View User",
    key: "view-user",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/user/view/:id",
    component: <ProtectedRoute component={<ViewUser />} />, // Protect this route
  },

  // For Usertypes
  {
    type: "title",
    id: 5,
    name: "User Types",
    key: "usertypes",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/usertypes",
    component: <ProtectedRoute component={<UserTypesList />} />, // Protect this route
  },
  {
    type: "none",
    id: 5,
    name: "Add User Types",
    key: "add-usertype",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/usertype/add",
    component: <ProtectedRoute component={<AddUsertype />} />, // Protect this route
  },
  {
    type: "none",
    id: 5,
    name: "Edit User Type",
    key: "edit-usertype",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/usertype/view/:id",
    component: <ProtectedRoute component={<ViewUserType />} />, // Protect this route
  },

  // For Courses
  {
    type: "title",
    id: 6,
    name: "Courses",
    key: "courses",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/courses",
    component: <ProtectedRoute component={<Courses />} />, // Protect this route
  },
  {
    type: "none",
    id: 6,
    name: "Add Course",
    key: "add-course",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/courses/add",
    component: <ProtectedRoute component={<AddCourse />} />, // Protect this route
  },
  {
    type: "none",
    id: 6,
    name: "Edit Course",
    key: "edit-course",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/courses/edit/:id",
    component: <ProtectedRoute component={<EditCourse />} />, // Protect this route
  },

  // For Subjects
  {
    type: "title",
    id: 7,
    name: "Subjects",
    key: "subjects",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/subjects",
    component: <ProtectedRoute component={<Subjects />} />, // Protect this route
  },
  {
    type: "none",
    id: 7,
    name: "Add Subject",
    key: "add-subject",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/subject/add",
    component: <ProtectedRoute component={<AddSubject />} />, // Protect this route
  },
  {
    type: "none",
    id: 7,
    name: "Edit Subject",
    key: "edit-subject",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/subject/edit/:id",
    component: <ProtectedRoute component={<Subjects />} />, // Protect this route
  },

  // For Chapters
  {
    type: "title",
    id: 8,
    name: "Chapters",
    key: "chapters",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/chapters",
    component: <ProtectedRoute component={<Chapters />} />, // Protect this route
  },
  {
    type: "none",
    id: 8,
    name: "Chapters",
    key: "add-chapter",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/chapter/add",
    component: <ProtectedRoute component={<Chapters />} />, // Protect this route
  },
  {
    type: "none",
    id: 8,
    name: "Chapters",
    key: "edit-chapter",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/chapter/edit/:id",
    component: <ProtectedRoute component={<Chapters />} />, // Protect this route
  },

  // For Topics
  {
    type: "title",
    id: 9,
    name: "Topics",
    key: "topics",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/topics",
    component: <ProtectedRoute component={<Topics />} />, // Protect this route
  },
  {
    type: "none",
    id: 9,
    name: "Topics",
    key: "add-topic",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/topic/add",
    component: <ProtectedRoute component={<AddTopic />} />, // Protect this route
  },
  {
    type: "none",
    id: 9,
    name: "Topics",
    key: "edit-topic",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/topic/edit/:id",
    component: <ProtectedRoute component={<Topics />} />, // Protect this route
  },

  // For AcademicList
  {
    type: "collapse",
    id: 10,
    name: "Academic",
    key: "academic",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/academic",
    component: <ProtectedRoute component={<AcademicList />} />,
    children: [
      {
        id: 100,
        type: "title",
        key: "colleges",
        name: "College",
        icon: null,
        route: "/colleges",
        component: <ProtectedRoute component={<AcademicList />} />,
      },
      {
        id: 101,
        type: "title",
        key: "institutes",
        name: "Institute",
        icon: null,
        route: "/institutes",
        component: <ProtectedRoute component={<AcademicList />} />,
      },
      {
        id: 102,
        type: "title",
        key: "schools",
        name: "School",
        icon: null,
        route: "/schools",
        component: <ProtectedRoute component={<AcademicList />} />,
      },
      {
        id: 103,
        type: "title",
        key: "universities",
        name: "University",
        icon: null,
        route: "/universities",
        component: <ProtectedRoute component={<AcademicList type="universities" />} />,
      },
    ],
  },

  {
    type: "none",
    id: 14,
    name: "Colleges",
    key: "colleges",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/colleges",
    component: <ProtectedRoute component={<AcademicList />} />, // Protect this route
  },
  {
    type: "none",
    id: 15,
    name: "Schools",
    key: "schools",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/schools",
    component: <ProtectedRoute component={<AcademicList />} />, // Protect this route
  },
  {
    type: "none",
    id: 16,
    name: "Institutes",
    key: "institutes",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/institutes",
    component: <ProtectedRoute component={<AcademicList />} />, // Protect this route
  },
  {
    type: "none",
    id: 17,
    name: "Universities",
    key: "universities",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/universities",
    component: <ProtectedRoute component={<AcademicList />} />, // Protect this route
  },

  {
    type: "none",
    id: 10,
    name: "AcademicList",
    key: "add-academic",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/academic/add",
    component: <ProtectedRoute component={<AddAcademic />} />, // Protect this route
  },
  {
    type: "none",
    id: 10,
    name: "AcademicList",
    key: "edit-academic",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/academic/edit/:id",
    component: <ProtectedRoute component={<EditAcademic />} />, // Protect this route
  },

  // For events & activities
  {
    type: "title",
    id: 3,
    name: "Events & Activities",
    key: "events",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/activities",
    component: <ProtectedRoute component={<Activities />} />, // Protect this route
  },
  {
    type: "none",
    id: 3,
    name: "Events & Activities",
    key: "events",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/activity/detail/:id",
    component: <ProtectedRoute component={<Activities />} />, // Protect this route
  },

  // For Transactions
  {
    type: "title",
    id: 11,
    name: "Transactions",
    key: "transactions",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/transaction",
    component: <ProtectedRoute component={<Transaction />} />, // Protect this route
  },

  {
    type: "title",
    id: 13,
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <ProtectedRoute component={<Overview />} />, // Protect this route
  },
];

export default routes;
